<template>
  <v-container class="pt-12">

    <!--========================================-->
    <!-- Exercicis guardats                     -->
    <!--========================================-->
    <div v-if="collectionInfo">
      <h1 class="ml-2">Col·lecció {{collectionInfo.collection_name}}</h1>


      <!--========================================-->
      <!-- Llista Problemes                       -->
      <!--========================================-->
      <v-row class="pa-2 mt-8">
        <v-col v-for="(problem, index) in problems" :key="problem.problem_id" cols="6" md="3">
          <v-responsive aspect-ratio="1" class="rounded-lg shadow-small">

            <v-card
              @click="openProblem(index)"
              :ripple="false"
              color="grey lighten-3"
              flat
              height="100%"
              width="100%"
              class="rounded-lg align-center"
            >
              <v-img v-if="problem" :src="getImage(problem)" width="100%" aspect-ratio="1"></v-img>
              <div v-else style="height: 100%; width: 100%" class="text-center d-flex flex-grow-1">
                <v-icon large class="ma-auto">{{ mdiBookmarkOffOutline }}</v-icon>
              </div>
            </v-card>
          </v-responsive>

          <p class="d-inline-block text-truncate title mb-0 mt-2"
             style="flex: 1">
            {{ problem.subject_name }} {{problem.year}} S{{problem.serie}}
          </p>
        </v-col>
      </v-row>


      <!--========================================-->
      <!-- Dialog Problema                        -->
      <!--========================================-->
      <v-dialog v-model="dialogProblem"
                content-class="rounded-lg"
                overlay-color="grey"
                :fullscreen="true"
      >
      <v-card>
      <div style="max-width: 800px; height: 100%;" class="d-flex flex-column ma-auto">
        <v-btn
        style="position: absolute; top: 10px; right: 10px;"
        @click="dialogProblem = false" icon>
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>

        <!-- Indicació de navegació amb tecles -->
        <div class="navigation-hint pa-2 text-center d-flex align-center justify-center">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-card
                    @click="previousProblem"
                    class="d-flex align-center justify-center mr-3 shadow-sm"
                    width="30"
                    height="30"
                    outlined
                    v-on="on"
                    >
                        <v-icon small>{{ mdiArrowLeft }}</v-icon>
                    </v-card>
                </template>
                <span class="caption">Anterior problema</span>
            </v-tooltip>

            <span class="caption">Utilitza les fletxes del teclat per navegar entre problemes</span>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-card
                    @click="nextProblem"
                    class="d-flex align-center justify-center ml-3 shadow-sm"
                    width="30"
                    height="30"
                    outlined
                    v-on="on"
                    >
                        <v-icon small>{{ mdiArrowRight }}</v-icon>
                    </v-card>
                </template>
                <span class="caption">Següent problema</span>
            </v-tooltip>
        </div>

        <!-- Indicador de problema actual -->
        <div class="text-center mb-2">
          <span class="caption">Problema {{ currentProblemIndex + 1 }} de {{ problems.length }}</span>
        </div>

        <problem-component
                  v-if="selectedProblem"
                  style="max-width: 800px;"
                  :problem="selectedProblem"
                  class="ma-auto pb-12"
                  :key="selectedProblem.problem_id + '_problem'"
        ></problem-component>

        <!-- Botons de navegació -->
        <div class="navigation-buttons d-flex justify-space-between pa-4">
          <v-btn
            icon
            :disabled="currentProblemIndex <= 0"
            @click="previousProblem"
          >
            <v-icon>{{ mdiArrowLeft }}</v-icon>
          </v-btn>

          <v-btn
            icon
            :disabled="currentProblemIndex >= problems.length - 1"
            @click="nextProblem"
          >
            <v-icon>{{ mdiArrowRight }}</v-icon>
          </v-btn>
        </div>
    </div>
</v-card>
      </v-dialog>



    </div>
  </v-container>
</template>

<script>
import { mdiBookmarkOffOutline, mdiClose, mdiArrowLeft, mdiArrowRight } from '/src/assets/mdi.json'
import ProblemComponent from "../../components/ProblemComponent.vue";

export default {
  name: "CollectionPage",
  components: {
    ProblemComponent
  },
  data: () => {
    return {
      problems: [],
      collectionInfo: null,
      dialogProblem: false,
      selectedProblem: null,
      currentProblemIndex: 0,
      mdiBookmarkOffOutline: mdiBookmarkOffOutline,
      mdiClose: mdiClose,
      mdiArrowLeft: mdiArrowLeft,
      mdiArrowRight: mdiArrowRight,
      pageLimit: 7
    }
  },
  mounted() {
    // Afegir event listener per a les tecles de fletxes a nivell de document
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    // Eliminar event listener quan es destrueix el component
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    async fetchCollectionInfo() {
      try {
        const id = this.$route.params.collection_id;
        const {data} = await this.axios.get('/collections-info/' + id, {
          headers: {
            Authorization: this.$root.token.value
          }
        })
        this.collectionInfo = data;
      } catch (e) {
        throw e
      }
    },
    async fetchCollections() {
      try {
        const id = this.$route.params.collection_id;
        const {data} = await this.axios.get(`/problems/collections/${id}/${this.pageLimit}`, {
          headers: {
            Authorization: this.$root.token.value
          }
        })
        this.problems = data;
      } catch (e) {
        throw e
      }
    },
    getImage(problem) {
      return `https://examenselectivitat.cat:3000/api/images/${problem.subject_path}/${problem.year}/${problem.serie}/${problem.problem_id}/problem`
    },
    openProblem(index) {
      this.currentProblemIndex = index;
      this.selectedProblem = this.problems[index];
      this.dialogProblem = true;
    },
    nextProblem() {
      if (this.currentProblemIndex < this.problems.length - 1) {
        this.currentProblemIndex++;
        this.selectedProblem = this.problems[this.currentProblemIndex];
      }
    },
    previousProblem() {
      if (this.currentProblemIndex > 0) {
        this.currentProblemIndex--;
        this.selectedProblem = this.problems[this.currentProblemIndex];
      }
    },
    handleKeyDown(event) {
      // Només processar les tecles de fletxes si el diàleg està obert
      if (this.dialogProblem) {
        if (event.key === 'ArrowLeft') {
          this.previousProblem();
          event.preventDefault();
        } else if (event.key === 'ArrowRight') {
          this.nextProblem();
          event.preventDefault();
        }
      }
    }
  },
  watch: {
    '$root.token': {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.fetchCollectionInfo();
          await this.fetchCollections();
        }
      }
    }
  }
}
</script>

<style scoped>
.navigation-hint {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-top: 60px;
  margin-bottom: 8px;
}

.navigation-buttons {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  max-width: 800px;
  margin: 0 auto;
  z-index: 2;
}
</style>
